<template>
  <div>
    <b-row>
      <b-col md="6">
        <b-form-group
          label="Date de début"
          label-for="nom-input"
        >
          <b-form-input
            id="nom-input"
            ref="date1"
            v-model="dateDebut"
            type="date"
            required
          />
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group
          label="Date de fin"
          label-for="nom-input"
        >

          <b-form-input
            id="nom-input"
            ref="date2"
            v-model="dateFin"
            type="date"
            required
          />
        </b-form-group>
      </b-col>
      <b-col md="12">
        <div style="text-align: center">
          <b-overlay
            :show="show"
            rounded="sm"
            spinner-variant="primary"
            spinner-small
            class="d-inline-block"
          >
            <b-button
              id="toggle-btn"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              v-b-modal.distributeur-modal-prevent-closing
              variant="outline-primary"
              class="btn_export"
              @click="getVisites"
            >
              <feather-icon icon="SearchIcon"/>
              Filtrer
            </b-button>
          </b-overlay>
        </div>

      </b-col>
    </b-row>
    <hr>
    <b-row>
      <b-col md="12">
        <b-card>
          <b-card>
            <!-- input search -->
            <div class="custom-search d-flex justify-content-end">
              <b-form-group>
                <div class="d-flex align-items-center">
                  <label class="mr-1">Recherche
                  </label>
                  <b-form-input
                    v-model="searchTerm"
                    placeholder="Recherche"
                    type="text"
                    class="d-inline-block"
                  />
                </div>
              </b-form-group>
              <!-- primary -->
              <b-dropdown
                id="dropdown-1"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                text="Exporter"
                variant="primary"
                class="ml-2 btn_export"
              >
                <b-dropdown-item @click="exportCSV">
                  CSV
                </b-dropdown-item>
                <b-dropdown-item @click="exportExcel">
                  MS-Excel
                </b-dropdown-item>
              </b-dropdown>
            </div>
            <vue-good-table
              id="list"
              ref="myTable"
              :columns="columns"
              :rows="visites"
              :rtl="direction"
              :search-options="{
                enabled: true,
                externalQuery: searchTerm }"
              :pagination-options="{
                enabled: true,
                perPage:pageLength
              }"
            >
              <template
                slot="table-row"
                slot-scope="props"
              >

                <!-- Column: Status -->
                <span v-if="props.column.field === 'status'">
                  <b-badge :variant="statusVariant(props.row.status)">
                    {{ props.row.status }}
                  </b-badge>
                </span>

                <!-- Column: Action -->
                <span v-else-if="props.column.field === 'action'">
                  <b-button-group>
                    <b-button
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      variant="outline-primary"
                      :to="{ name: 'tourners-visite-details', params: { id: props.row.id } }"
                      target="_blank"
                    >
                      Voir
                    </b-button>
                    <b-button
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      variant="outline-danger"
                      @click="handleDelete(props.row.id)"
                    >
                      Supprimer
                    </b-button>
                  </b-button-group>
                </span>

                <!-- Column: Common -->
                <span v-else>
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </template>

              <!-- pagination -->
              <template
                slot="pagination-bottom"
                slot-scope="props"
              >
                <div class="d-flex justify-content-between flex-wrap">
                  <div class="d-flex align-items-center mb-0 mt-1">
                    <span class="text-nowrap">
                      Affichage de 1 à
                    </span>
                    <b-form-select
                      v-model="pageLength"
                      :options="['3','5','10']"
                      class="mx-1"
                      @input="(value)=>props.perPageChanged({currentPerPage:value})"
                    />
                    <span class="text-nowrap "> sur {{ props.total }} élements </span>
                  </div>
                  <div>
                    <b-pagination
                      :value="1"
                      :total-rows="props.total"
                      :per-page="pageLength"
                      first-number
                      last-number
                      align="right"
                      prev-class="prev-item"
                      next-class="next-item"
                      class="mt-1 mb-0"
                      @input="(value)=>props.pageChanged({currentPage:value})"
                    >
                      <template #prev-text>
                        <feather-icon
                          icon="ChevronLeftIcon"
                          size="18"
                        />
                      </template>
                      <template #next-text>
                        <feather-icon
                          icon="ChevronRightIcon"
                          size="18"
                        />
                      </template>
                    </b-pagination>
                  </div>
                </div>
              </template>
            </vue-good-table>
          </b-card>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BBadge, BButton, BButtonGroup,
  BCard,
  BCol,
  BDropdown,
  BDropdownItem,
  BFormGroup,
  BFormInput,
  BFormSelect, BOverlay,
  BPagination,
  BRow,
} from 'bootstrap-vue'
import VueGoodTable from 'vue-good-table/src/components/Table.vue'
import store from '@/store'
import Ripple from 'vue-ripple-directive'
import htmlToPaper from 'vue-html-to-paper'
import { API_URL } from '@/helpers/global-scops'
import showToast, { getCurrentDate, getFirstDayOfCurrentMonth, premiereDateDeLaSemaine } from '@/helpers/methodes'

export default {
  name: 'VisiteListeMainVue',
  components: {
    BOverlay,
    BButtonGroup,
    BButton,
    BBadge,
    VueGoodTable,
    BCard,
    BCol,
    BRow,
    BDropdown,
    BFormInput,
    BDropdownItem,
    BFormGroup,
    BFormSelect,
    BPagination,
  },
  directives: {
    Ripple,
    htmlToPaper,
  },
  props: {
    // eslint-disable-next-line vue/require-default-prop
    tourner: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      show: false,
      dateDebut: '',
      dateFin: '',
      rows: [],
      visites: [],
      types: [],
      tourneData: {},
      pageLength: 10,
      searchTerm: '',
      columns: [
        {
          label: 'Date',
          field: 'date',
        },
        {
          label: 'Commercial',
          field: 'commercial',
        },
        {
          label: 'PDV',
          field: 'point_de_vente',
        },
        {
          label: 'Region',
          field: 'region',
        },
        {
          label: 'Zone',
          field: 'zone',
        },
        {
          label: 'Secteur',
          field: 'secteur',
        },
        {
          label: 'Actions',
          field: 'action',
        },
      ],
      arrondissements: [],
    }
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Current: 'light-primary',
        Professional: 'light-success',
        Rejected: 'light-danger',
        Resigned: 'light-warning',
        Applied: 'light-info',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
  },
  mounted() {
    this.dateDebut = premiereDateDeLaSemaine()
    this.dateFin = getCurrentDate()
    this.getVisites()
  },
  methods: {
    exportExcel() {
    },
    exportCSV() {
      const header = this.columns.map(column => column.label)
      const data = this.rows.map(row => this.columns.map(column => row[column.field]))
      let csvData = `${header.join(',')}\n`
      csvData += data.map(row => row.join(','))
        .join('\n')
      const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' })
      const url = URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.setAttribute('href', url)
      link.setAttribute('download', `${this.filename}.csv`)
      link.style.visibility = 'hidden'
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    },

    getVisites() {
      this.show = true
      this.$http.get(`${API_URL}op-visite-pdv/?date_debut=${this.dateDebut}&date_fin=${this.dateFin}`)
        .then(response => {
          // met dans visites les pdv du commercial et qui ne sont pas dans rows
          this.visites = response.data
          this.show = false
          // this.visites = response.data
        })
        .catch(error => {
          this.show = false
          console.log(error)
        })
    },
    handleDelete(id) {
      // eslint-disable-next-line no-restricted-globals,no-alert
      if (confirm('Êtes-vous sûr de vouloir supprimer cet élément ?')) {
        this.$http.delete(`${API_URL}visites/${id}/`)
          .then(response => {
            console.log(response.data)
            showToast(this, 'danger', 'Suppression', 'Element supprimé')
            this.getVisites()
          })
          .catch(error => {
            console.log(error)
          })
      } else {
        // Annuler la suppression ici
      }
    },
  },
}
</script>

<style scoped>

</style>
